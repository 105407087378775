import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  useMemo
} from "react";

// Third-party
import {
  Color,
  Rectangle,
  Camera,
  Viewer,
  CesiumTerrainProvider,
  GeographicProjection,
  GeoJsonDataSource,
  CustomDataSource,
  IonResource
} from "cesium/Build/Cesium/Cesium";
import CesiumNavigation from "cesium-navigation-es6";
import { useTranslation } from "react-i18next";
import { polygon, polygonToLine } from "@turf/turf";

// Components
import { handleUserKeyPress } from "components/Dashboard3D/CesiumKeyboardHandler";
import { OpenStreetMapNominatimGeocoder } from "components/Dashboard3D/customGeocoder";

// Context
import { AppContext } from "AppProvider";
import { Map3DContext } from "Map3DProvider";
import { useLocalSettingsContext } from "context/LocalSettingsContext";
import { useUiContext } from "context/UiContext";
import { usePaths } from "context/PathsContext";

// Hooks
import useCesiumPath from "hooks/useCesium";

// Cesium Utils
import {
  getColorByString,
  getDatasourceLayerByName,
  createGeocoderIcon,
  changeFlagAlphaById,
  changeCesiumBasemap,
  drawProjectPoints
} from "components/Dashboard3D/CesiumUtils";
import { loadIntermediatePoints } from "components/Dashboard3D/CesiumLayerUtils";

// Constants & Other
import { DEFAULT_COLOR, SCENARIO_SETTINGS_TAB } from "assets/global";

import {
  projectVectorName,
  optimalPathVectorName,
  intermediatePointName,
  startPointScenarioName,
  endPointScenarioName,
  endPointProjectName,
  startPointProjectName
} from "components/Dashboard3D/CesiumConstants";

import useStyles from "./styles";

// Constants
const WHITE = Color.WHITE;
const projectColor = getColorByString("#FFFFFF", 0.1);
const projectBorderColor = getColorByString("#73BAFA");
const geoCoderIcon = getColorByString("#e5be01");

/**
 * Component for Cesium.
 * Create and load layers in cesium 3D globe
 */
const CesiumGlobeReborn = props => {
  const { t } = useTranslation();

  const appContext = useContext(AppContext);
  const [uiState] = useUiContext();
  const [pathsState] = usePaths();
  const cesiumPath = useCesiumPath(pathsState.availableTechnologies);

  const {
    mapLoaded,
    restoreMapHandlers,
    cameraMode,
    refreshWireSource,
    ...map3DContext
  } = useContext(Map3DContext);

  const [viewer, setViewer] = useState();
  const [globeReady, setGlobeReady] = useState();

  const prevValues = useRef(appContext.state);
  const containerHeight = useRef(document.body.offsetHeight);
  const baseMap = useRef();

  const classes = useStyles();
  const { localSettingsState, setActiveBaseMap } = useLocalSettingsContext();

  const projectArea = useMemo(
    () => appContext.state.project.area,
    [appContext.state.project.area]
  );

  const projectScenarios = useMemo(
    () => appContext.state.scenarios,
    [appContext.state.scenarios]
  );

  const projectStartPoint = useMemo(
    () => appContext.state.project?.start_point?.coordinates,
    [appContext.state.project?.start_point?.coordinates]
  );

  const projectEndPoint = useMemo(
    () => appContext.state.project?.end_point?.coordinates,
    [appContext.state.project?.end_point?.coordinates]
  );

  const activeScenario = useMemo(
    () => appContext.state.scenarios[appContext.state.active_scenario],
    [appContext.state.active_scenario, appContext.state.scenarios]
  );

  const scenarioStartPoint = useMemo(() => {
    return activeScenario?.start_point?.coordinates;
  }, [activeScenario?.start_point?.coordinates]);

  const scenarioEndPoint = useMemo(() => {
    return activeScenario?.end_point?.coordinates;
  }, [activeScenario?.end_point?.coordinates]);

  const needTowerRedraw = useMemo(
    () => map3DContext.state.needTowerRedraw,
    [map3DContext.state.needTowerRedraw]
  );

  const mapHeight = useMemo(
    () => document.body.offsetHeight - uiState.bottomDrawerHeight,
    [uiState.bottomDrawerHeight]
  );

  /**
   * Create cesium map, create cesium datasources
   */
  const createMap = useCallback(() => {
    if (appContext.project.length !== 0) {
      let bbox = appContext.project.area_bbox;
      let extent = Rectangle.fromDegrees(bbox[0], bbox[1], bbox[2], bbox[3]);
      Camera.DEFAULT_VIEW_RECTANGLE = extent;
      Camera.DEFAULT_VIEW_FACTOR = 0;
    }
    // Trick to remove cesium credits
    const dummyCredit = document.createElement("div");
    // Create Cesium Map
    const newViewer = new Viewer("cesiumContainer", {
      contextOptions: {
        webgl: {
          powerPreference: "high-performance",
          allowTextureFilterAnisotropic: false
        }
      },
      terrainProvider: new CesiumTerrainProvider({
        url: IonResource.fromAssetId(1)
      }),
      mapProjection: new GeographicProjection(),
      imageryProvider: false,
      animation: false,
      baseLayerPicker: false,
      fullscreenButton: false,
      geocoder: new OpenStreetMapNominatimGeocoder(),
      homeButton: false,
      infoBox: false,
      sceneModePicker: false,
      selectionIndicator: false,
      timeline: false,
      navigationHelpButton: false,
      requestRenderMode: true,
      maximumRenderTimeChange: Infinity,
      //Saves GPU memory
      useBrowserRecommendedResolution: true,
      scene3DOnly: true,
      automaticallyTrackDataSourceClocks: false,
      creditContainer: dummyCredit
    });

    // Start add default background
    const localBackground = localSettingsState?.localSettings?.baseMap;

    let background;
    if (localBackground) {
      background = appContext
        .getBackgrounds3D()
        ?.find(item => Number(item.id) === Number(localBackground.id));
      if (!background) {
        background = appContext.getBackgrounds3D()[0];
      }
    } else {
      background = appContext.getBackgrounds3D()[0];
    }

    changeCesiumBasemap(newViewer, background);
    newViewer.scene.requestRender();
    setViewer(newViewer);
    props.onViewerReady(newViewer);

    setActiveBaseMap(background);
    baseMap.current = background;
  }, [
    appContext.backgrounds,
    appContext.project.area_bbox,
    appContext.project.length,
    localSettingsState?.localSettings?.baseMap,
    props
  ]);

  /**
   * Save Gpu in old machines
   */
  const saveGpu = useCallback(() => {
    if (viewer) {
      let scene = viewer.scene;
      let globe = scene.globe;

      scene.logarithmicDepthBuffer = true;
      globe.depthTestAgainstTerrain = true;

      // Prevent Blurry texts
      viewer.resolutionScale = window.devicePixelRatio;
      viewer.scene.postProcessStages.fxaa.enabled = false;

      globe.baseColor = WHITE;
      scene.highDynamicRange = false;
      globe.showGroundAtmosphere = false;
      scene.moon = undefined;
    }
  }, [viewer]);

  /**
   * Load Default Cesium Datasources
   */
  const loadDatasources = useCallback(() => {
    if (viewer) {
      //Projects default dataSources
      let projectVectorSource = new GeoJsonDataSource(projectVectorName);
      viewer.dataSources.add(projectVectorSource);

      //Towers pylons default dataSources
      let optimalPathVectorSource = new GeoJsonDataSource(
        optimalPathVectorName
      );
      viewer.dataSources.add(optimalPathVectorSource);

      // Intermediate Points
      let intermediatePointSource = new CustomDataSource(intermediatePointName);

      viewer.dataSources.add(intermediatePointSource);

      viewer.dataSources.raiseToTop(optimalPathVectorSource);
      viewer.dataSources.lowerToBottom(projectVectorSource);
    }
  }, [viewer]);

  /**
   * Cesium GeoCoder
   */
  const customGeoCoder = useCallback(() => {
    if (viewer) {
      //Translate Geocoder placeholder
      document.querySelector(".cesium-geocoder-input").placeholder =
        t("geocoder.Search");

      // Trick move geocoder div
      let fragment = document.getElementById("geocoderContainer");
      fragment.style.visibility = "hidden";

      let cont = document.getElementsByClassName(
        "cesium-viewer-geocoderContainer"
      )[0];
      fragment.appendChild(cont);

      // Remove Cesium classes
      document
        .getElementsByClassName("cesium-geocoder-searchButton")[0]
        ?.remove();

      // Override Geocoder destinationFound
      viewer.geocoder.viewModel.destinationFound = (
        _viewModel,
        destination
      ) => {
        // Remove old Geocoder Pin entities
        viewer.entities.removeAll();

        const center = Rectangle.center(destination);
        viewer.camera.flyTo({
          destination: destination
        });
        viewer.entities.add(
          createGeocoderIcon(center.longitude, center.latitude, geoCoderIcon)
        );

        // Close Geocoder
        let container = document.getElementById("geocoderContainer");
        container.style.visibility = "hidden";
      };
    }
  }, [t, viewer]);

  /**
   * Create Cesium Navigator
   */
  const cesiumNavigator = useCallback(() => {
    if (viewer) {
      let options = {};
      options.enableCompass = true;
      options.enableZoomControls = false;
      options.enableDistanceLegend = true;
      options.enableCompassOuterRing = true;

      CesiumNavigation(viewer, options);
      appContext.moveCesiumControls(appContext.state.rightOpen);
    }
  }, [appContext, viewer]);

  /**
   * Key down listener to move map with arrows
   * @param {*} param
   * @returns
   */
  const keydown = useCallback(() => {
    return e => {
      handleUserKeyPress(e, viewer, cameraMode);
    };
  }, [cameraMode, viewer]);

  /**
   * Method to fly to the project area.
   */
  const zoomToLayer = useCallback(() => {
    if (viewer) {
      const projectVector = getDatasourceLayerByName(viewer, projectVectorName);
      if (!projectVector) return;
      viewer.flyTo(projectVector);
    }
  }, [viewer]);

  /**
   * Effect to load the porject area and zoom to it.
   */
  useEffect(() => {
    if (viewer && globeReady) {
      const projectVector = getDatasourceLayerByName(viewer, projectVectorName);
      if (!projectVector) return;
      //Create project layer only is this is empty.Prevent layer flickering
      // remove old entities
      projectVector.entities.removeAll();

      if (projectArea?.coordinates) {
        // Entity as a LineString, not a Polygon
        const poly = polygon(projectArea.coordinates);
        const line = polygonToLine(poly);

        // Load project area
        projectVector.load(line.geometry, {
          clampToGround: true,
          stroke: projectBorderColor,
          strokeWidth: 4
        });
      }

      viewer.scene.requestRender();
      zoomToLayer();
    }
  }, [globeReady, projectArea, viewer, zoomToLayer]);

  useEffect(() => {
    if (viewer) {
      if (
        containerHeight.current !== uiState.bottomDrawerHeight &&
        uiState.bottomDrawerOpen
      ) {
        document.getElementById("cesiumContainer").style.height = mapHeight;
        document
          .getElementById("cesiumContainer")
          .setAttribute("style", "height:" + mapHeight + "px");

        document
          .getElementById("cesiumContainer")
          .getElementsByClassName("cesium-viewer")[0].style.height = mapHeight;
        document
          .getElementById("cesiumContainer")
          .getElementsByClassName("cesium-viewer")[0]
          .setAttribute("style", "height:" + mapHeight + "px");

        containerHeight.current = uiState.bottomDrawerHeight;
        viewer.resize();
        viewer.scene.requestRender();
      } else if (!uiState.bottomDrawerOpen) {
        document.getElementById("cesiumContainer").style.height = "100%";
        document
          .getElementById("cesiumContainer")
          .setAttribute("style", "height: 100%");

        document
          .getElementById("cesiumContainer")
          .getElementsByClassName("cesium-viewer")[0].style.height = "100%";
        document
          .getElementById("cesiumContainer")
          .getElementsByClassName("cesium-viewer")[0]
          .setAttribute("style", "height: 100%");

        containerHeight.current = 0;
        viewer.resize();
        viewer.scene.requestRender();
      }
    }
  }, [
    mapHeight,
    uiState.bottomDrawerHeight,
    uiState.bottomDrawerOpen,
    viewer,
    zoomToLayer
  ]);

  /**
   * Effect to load the project points.
   */
  useEffect(() => {
    if (viewer && globeReady) {
      const projectVector = getDatasourceLayerByName(viewer, projectVectorName);
      if (!projectVector) return;
      drawProjectPoints(
        projectVector,
        projectStartPoint,
        startPointProjectName
      );

      drawProjectPoints(
        projectVector,
        projectEndPoint,
        endPointProjectName,
        false
      );

      viewer.scene.requestRender();
    }
  }, [globeReady, projectEndPoint, projectStartPoint, viewer]);

  /**
   *  If scenario setting is opened, we show a ghosty image
   *  of the start and end point if they are different
   *  than the scenario points.
   */
  useEffect(() => {
    if (viewer && globeReady) {
      const projectVector = getDatasourceLayerByName(viewer, projectVectorName);
      if (!projectVector) {
        return;
      }

      projectVector.entities.removeById(startPointScenarioName);
      projectVector.entities.removeById(endPointScenarioName);

      // Load Point when load the project
      if (scenarioStartPoint) {
        drawProjectPoints(
          projectVector,
          scenarioStartPoint,
          startPointScenarioName
        );
      }
      if (scenarioEndPoint) {
        drawProjectPoints(
          projectVector,
          scenarioEndPoint,
          endPointScenarioName,
          false
        );
      }

      // Event when change the project tab
      const scenarioStart = projectVector?.entities.getById(
        startPointScenarioName
      );
      const scenarioEnd = projectVector?.entities.getById(endPointScenarioName);
      let alphaPoint = 0;

      if (
        appContext.state.right_tab === SCENARIO_SETTINGS_TAB ||
        appContext.state.isScenarioPointsEditorOpen
      ) {
        alphaPoint = 0.4;
      }

      changeFlagAlphaById(
        projectVector,
        startPointProjectName,
        scenarioStart ? alphaPoint : 1
      );

      changeFlagAlphaById(
        projectVector,
        endPointProjectName,
        scenarioEnd ? alphaPoint : 1
      );

      viewer.scene.requestRender();
    }
  }, [
    appContext.state.right_tab,
    globeReady,
    scenarioEndPoint,
    scenarioStartPoint,
    viewer
  ]);

  /**
   * Load intermediate points.
   */
  useEffect(() => {
    if (viewer && globeReady) {
      if (appContext.state.scenario) {
        const intermediatePoints =
          appContext.state.scenario.intermediate_points;
        loadIntermediatePoints(viewer, intermediatePoints);
      }
    }
  }, [appContext.state.scenario, globeReady, viewer]);

  /**
   * Key down effect
   */
  useEffect(() => {
    // TODO:
    // This way of attaching the `keydown` listener interferes with
    // elements like `textarea`.  The ideal would be to attach the
    // listener to the Cesium container, but that implies some additional
    // tricks. Here's a working example (Lines 5 to 8 are key):
    // https://sandcastle.cesium.com/index.html?src=Camera%20Tutorial.html
    const controller = new AbortController();
    const { signal } = controller;

    document.addEventListener("keydown", keydown(), { signal });

    // Remove key down listener. Used to move the map using arrows
    return () => {
      if (controller) controller.abort();
    };
  }, [keydown]);

  /**
   * Redraw when terrain changes
   */
  useEffect(() => {
    if (viewer && globeReady && !needTowerRedraw) {
      const pathSource = getDatasourceLayerByName(
        viewer,
        optimalPathVectorName
      );
      if (!pathSource) {
        return;
      }
      //Remove all path entities
      const entities = pathSource?.entities;
      if (entities) entities.removeAll();

      const visibilitySettings =
        localSettingsState?.localSettings?.map?.visibility?.paths;
      const colorSettings =
        localSettingsState?.localSettings?.map?.color?.paths;

      if (visibilitySettings) {
        visibilitySettings.forEach(pathId => {
          const pathColor = colorSettings[pathId] || DEFAULT_COLOR;
          const p = pathsState.paths.find(p => p.id === pathId);
          const s = pathsState.scenarios.find(s => s.id === p.scenario);
          cesiumPath.showPath(s, true, p, p.id, pathColor, p.metadata);
        });
      }
      refreshWireSource(true);
    }
  }, [
    cesiumPath,
    globeReady,
    localSettingsState?.localSettings?.map?.color?.paths,
    localSettingsState?.localSettings?.map?.visibility?.paths,
    needTowerRedraw,
    pathsState.paths,
    pathsState.scenarios,
    refreshWireSource,
    viewer
  ]);

  /**
   * EFFECT 1
   * If there's no viewer, we create the map.
   */
  useEffect(() => {
    if (!viewer) {
      createMap();
    }
  }, [createMap, viewer]);

  /**
   * EFFECT 2
   * Once there's a viewer, we proceed to complete the
   * initizalization, attaching functions and other utils.
   */
  useEffect(() => {
    if (viewer && !globeReady) {
      // Save Gpu in old machines
      saveGpu();

      // Create Cesium Datasources
      loadDatasources();

      viewer.scene.requestRender();

      // Trick prevent unload basemap and terrain
      mapLoaded(viewer);

      viewer.scene.requestRender();

      // Custom Cesium Geocoder
      customGeoCoder();

      // Create Cesium Navigator
      cesiumNavigator();

      setGlobeReady(true);
      appContext.setMap3DReady(true);
    }
  }, [
    appContext,
    cesiumNavigator,
    customGeoCoder,
    globeReady,
    loadDatasources,
    map3DContext,
    mapLoaded,
    saveGpu,
    viewer
  ]);

  /**
   * EFFECT 3
   */
  useEffect(() => {
    if (viewer && globeReady) {
      if (projectScenarios.length > 0) {
        // Check if SCENARIO HAS CHANGED, if so, we reload the map and its features
        // for the new scenario.
        if (
          appContext.state.active_scenario !==
          prevValues.current.active_scenario
        ) {
          const projectVector = getDatasourceLayerByName(
            viewer,
            optimalPathVectorName
          );
          const startPointScenario = projectVector.entities.getById(
            startPointScenarioName
          );
          const startPointProject = projectVector.entities.getById(
            startPointProjectName
          );
          const endPointScenario =
            projectVector.entities.getById(endPointScenarioName);
          const endPointProject =
            projectVector.entities.getById(endPointProjectName);
          if (
            startPointScenario !== undefined &&
            startPointProject !== undefined
          ) {
            // We check if the scenario start point is null in context and the scenario start
            // point flag is in a different position than the project start point flag.
            // That means that we have to move the scenario flag to the project flag
            // position, so it looks like there's only project start point.
            if (
              scenarioStartPoint === null &&
              startPointScenario.position !== startPointProject.position
            ) {
              startPointScenario.position = startPointProject.position;
            }
          }
          if (endPointScenario !== undefined && endPointProject !== undefined) {
            // We check if the scenario end point is null in context and the scenario end
            // point flag is in a different position than the project end point flag.
            // That means that we have to move the scenario flag to the project flag
            // position, so it looks like there's only project end point.
            if (
              scenarioEndPoint === null &&
              endPointScenario.position !== endPointProject.position
            ) {
              endPointScenario.position = endPointProject.position;
            }
          }
        }
      }
    }
  }, [
    appContext.state.active_scenario,
    scenarioEndPoint,
    scenarioStartPoint,
    globeReady,
    viewer,
    projectScenarios.length
  ]);

  /**
   * UseEffect to load the project
   */
  useEffect(() => {
    if (viewer && globeReady) {
      // Remove current listener
      restoreMapHandlers(viewer.screenSpaceEventHandler);
      // Reset cursor, just in case
      viewer.container.style.cursor = "default";

      viewer.scene.requestRender();
    }
  }, [globeReady, restoreMapHandlers, viewer]);

  /**
   * Render method
   */
  return (
    <div
      style={{
        height: mapHeight + "px",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0
      }}
    >
      {/* Pylons hover result  */}
      <div id="hoverPylon" className={classes.hoverPylon}></div>
      {/* Tootip for show measurements results  */}
      <div id="cesium-tooltip" className={classes.cesiumTooltip}></div>
      {/* Identify show hover values  */}
      <div id="hoverDiv"></div>
      <div
        id={"geocoderContainer"}
        className={"custom-cesium-viewer-geocoderContainer"}
        style={{ left: uiState.leftDrawerWidth + 80 + "px" }}
      ></div>
      {/* Cesium container */}
      <div className={"cesiumWidget"} id={"cesiumContainer"}></div>
    </div>
  );
};
export default CesiumGlobeReborn;
