import { Resource, Rectangle } from "cesium/Build/Cesium/Cesium";

/**
 * Custom geocoder. It provides geocoding through the OpenStreetMap Nominatim service.
 * @alias OpenStreetMapNominatimGeocoder
 * @constructor
 */
function OpenStreetMapNominatimGeocoder() {}

/**
 * The function called to geocode using this geocoder service.
 *
 * @param {String} input The query to be sent to the geocoder service
 * @returns {Promise<GeocoderResult[]>}
 */
OpenStreetMapNominatimGeocoder.prototype.geocode = function (input) {
  let endpoint = "https://nominatim.openstreetmap.org/search";
  let resource = new Resource({
    url: endpoint,
    queryParameters: {
      format: "json",
      q: input
    }
  });

  return resource.fetchJson().then(function (results) {
    let bboxDegrees;
    return results.map(function (resultObject) {
      bboxDegrees = resultObject.boundingbox;
      return {
        displayName: resultObject.display_name,
        destination: Rectangle.fromDegrees(
          bboxDegrees[2],
          bboxDegrees[0],
          bboxDegrees[3],
          bboxDegrees[1]
        )
      };
    });
  });
};

export { OpenStreetMapNominatimGeocoder };
